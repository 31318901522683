import React from 'react'
import './loader.css'

const Loader = () => {
  return (
    <>
      <div  className="lds-ring">
      <span class="loader"></span>
      </div>
    </>
  )
}

export default Loader
